<template>
  <!-- главная страница с шахматкой для проживания и календарем свободных мест для активного отдыха -->
  <v-container id="calendar" fluid tag="section">
    <v-row>
      <!-- изменение даты через кнопки -->
      <div
        v-show="object_type != 'is_leisure'"
        class="col-md-7 col-sm-6 col-lg-5"
        :style="[$mobileCheck ? { padding: '1vw 12px' } : {}]"
      >
        <v-row :style="[$mobileCheck ? {} : {}]">
          <v-col cols="5" sm="4" md="3" class="py-0">
            <v-btn
              class="ma-2"
              outlined
              fab
              x-small
              color="primary"
              @click="prev"
            >
              <v-icon> mdi-arrow-left-bold </v-icon>
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              fab
              x-small
              color="primary"
              @click="next"
            >
              <v-icon> mdi-arrow-right-bold </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" sm="4" md="3" class="py-0">
            <v-menu
              ref="dialog"
              v-model="dialog"
              :close-on-content-click="false"
              :return-value.sync="today"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedCurrentDate"
                  label="Текущая дата:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="today"
                no-title
                scrollable
                locale="ru"
                :first-day-of-week="1"
              >
                <v-btn text color="primary" @click="dialog = false">
                  Отмена
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    dialog = false;
                    changePeriod({ period: period, today: today });
                    $refs.dialog.save(today);
                    newPeriodSegment();
                  "
                  :disabled="disabledBtn"
                >
                  Сохранить
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3" sm="4" md="3" class="py-0">
            <v-select
              v-model="period"
              :items="periods"
              label="Период"
              @change="
                changePeriod({ period: period, today: today });
                newPeriodSegment();
              "
            />
          </v-col>
        </v-row>
      </div>
      <!-- скачать excel -->
      <div class="col-sm-6 col-md-2 col-lg-2">
        <v-btn
          color="primary"
          @click="exportExcel"
          outlined
          v-if="object_type != 'is_leisure'"
          >Скачать EXCEL</v-btn
        >
      </div>
      <!-- карточка Состояние на сегодня -->
      <div
        class="col-md-3 col-sm-6 col-lg-5"
        v-show="object_type != 'is_leisure'"
        style="padding-top: 0 !important; padding-bottom: 0 !important"
      >
        <v-card>
          <v-card-title
            :style="[
              $mobileCheck
                ? {
                    paddingTop: '1.6vw',
                    paddingBottom: '1vw',
                    paddingLeft: '1vw',
                  }
                : { 'padding-top': '11px', 'padding-bottom': '17px' },
            ]"
          >
            <span style="padding-left: 4px; font-size: 16px; line-height: 1rem"
              >Состояние на сегодня</span
            >
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                class="p-0"
                :style="[
                  $mobileCheck
                    ? { paddingLeft: '1vw !important' }
                    : { 'padding-left': '15px !important' },
                ]"
              >
                <span class="font-small"
                  >Свободно:
                  <strong class="font-small">{{ free }}</strong></span
                >
                <br />
                <span class="font-small"
                  >Занято: <strong class="font-small">{{ busy }}</strong></span
                >
                <br />
                <span class="font-small"
                  >Занятые часы:
                  <strong class="font-small">{{ busy_times }}</strong></span
                >
                <br />
                <span class="font-small"
                  >Занятые койки:
                  <strong class="font-small">{{ busy_koika }}</strong></span
                >
              </v-col>
              <v-col class="p-0">
                <span class="font-small"
                  >Заезды:
                  <strong class="font-small">{{ check_in }}</strong></span
                >
                <br />
                <span class="font-small"
                  >Выезды:
                  <strong class="font-small">{{ check_out }}</strong></span
                ><br />
                <span class="font-small"
                  >Проживание:
                  <strong class="font-small">{{ residence }}</strong></span
                ><br />
              </v-col>
              <v-col class="p-0">
                <span class="font-small"
                  >Оплачено:
                  <strong class="font-small">{{ paid }}</strong></span
                ><br />
                <span class="font-small"
                  >Не оплачено:
                  <strong class="font-small">{{ not_paid }}</strong></span
                ><br />
                <span class="font-small"
                  >Частично оплачено:
                  <strong class="font-small">{{ partial_paid }}</strong></span
                ><br />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
    <!-- в мобилке уменьшить размер колонки Категории -->
    <v-row
      class="px-3 pb-3 d-block d-sm-none"
      v-if="object_type != 'is_leisure'"
    >
      <v-btn @click="changeWidth"
        ><v-icon>mdi-format-indent-decrease</v-icon></v-btn
      >
    </v-row>

    <!-- tooltip который открывается при hover на бронь -->
    <!--<div class="tooltip_relative">
      <div
        class="tooltip_absolute"
        v-if="$store.state.is_tooltip && $store.state.tooltip_info.left"
        :style="{
          left: getToolTipLeft($store.state.tooltip_info),
          top: getToolTipTop($store.state.tooltip_info),
        }"
      >
        <p v-if="$store.state.tooltip_info.item.common_amount" class="ma-0">
          Клиент: {{ $store.state.tooltip_info.item.client }}
        </p>
        <p class="ma-0">
          Источник: {{ $store.state.tooltip_info.item.source }}
        </p>
        <p class="ma-0">
          Статус: {{ $store.state.tooltip_info.item.status | getStatus }}
        </p>
        <p v-if="$store.state.tooltip_info.item.common_amount" class="ma-0">
          Осталось оплатить: {{ $store.state.tooltip_info.item.common_amount }}
        </p>
        <p v-if="$store.state.tooltip_info.item.status == 'koika'" class="ma-0">
          Койки: {{ $store.state.tooltip_info.item.free_time }}
        </p>
        <p class="ma-0">
          Дата: {{ $store.state.tooltip_info.item.time.start | convertDate }} -
          {{ $store.state.tooltip_info.item.time.end | convertDate }}
        </p>
        <v-row class="ma-0 pa-1">
          <v-col
            v-if="$store.state.tooltip_info.item.status != 'koika'"
            cols="6"
            class="ma-0 pa-1"
          >
            <v-btn
              :loading="saveBtnLoading"
              :disabled="disabledBtn"
              @click="openPopup($store.state.tooltip_info)"
              style="width: 100%"
              color="warning"
              >Открыть</v-btn
            >
          </v-col>
          <v-col cols="6" class="ma-0 pa-1">
            <v-btn
              v-if="
                $store.state.tooltip_info.item.status != 'time' &&
                btnLabel($store.state.tooltip_info.item)
              "
              :loading="saveBtnLoading"
              :disabled="disabledBtn"
              @click="changeStatus($store.state.tooltip_info)"
              style="width: 100%"
              color="warning"
              >{{ btnLabel($store.state.tooltip_info.item) }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div> -->

    <!-- шахматка для проживания -->
    <g-s-t-c
      v-if="object_type != 'is_leisure' && !loader"
      id="gstc"
      slot="pdf-content"
      v-cloak
      ref="calendar"
      @click="setCategories"
      :key="forceUpdate"
      :config="$store.state.config"
    />
    <!-- календарь для активного отдыха -->
    <activeRest v-show="object_type == 'is_leisure'" />

    <!-- нужно, попап с формой по созданию суточной брони -->
    <v-dialog
      v-model="$store.state.bookingModal"
      max-width="800px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <question-reserve @outClick="outClick" />
      </v-card>
    </v-dialog>
    <!-- нужно, попап с квизом опреденной брони -->
    <v-dialog
      v-model="$store.state.reserve"
      max-width="1200px"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <booking-modal ref="booking_modal" :clear="outClick" />
      </v-card>
    </v-dialog>

    <!-- нужно, попап ремонт -->
    <v-dialog v-model="$store.state.openRepair" width="500">
      <v-card>
        <v-card-text> Закрыто на ремонт </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="openRepair"
            :disabled="disabledBtn"
          >
            Открыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- нужно, попап блок -->
    <v-dialog v-model="$store.state.isBlockOpen" width="1000">
      <v-card class="pa-4">
        <v-col>
          <v-row>
            <v-col cols="6">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="getblockSteady().start"
                    label="Период с"
                    prepend-icon="mdi-calendar-blank-multiple"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  class="mt-0 mb-0"
                  no-title
                  v-model="getblockSteady().start"
                  @input="
                    getCategories(getblockSteady());
                    getblockSteady().startModal = false;
                  "
                />
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="getblockSteady().end"
                    label="По"
                    prepend-icon="mdi-calendar-blank-multiple"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  no-title
                  class="mt-0 mb-0"
                  v-model="getblockSteady().end"
                  @input="
                    getCategories(getblockSteady());
                    getblockSteady().endModal = false;
                  "
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-btn color="primary" @click="saveBlocks" :disabled="disabledBtn">
              Сохранить
            </v-btn>
            <v-btn @click="removeBlocks" :disabled="disabledBtn">
              Удалить
            </v-btn>
            <v-btn @click="cancelBlocks" :disabled="disabledBtn">
              Отмена
            </v-btn>
            <v-btn @click="reserveBlocks" :disabled="disabledBtn">
              резерв
            </v-btn>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>

    <!-- нужно, попап с формой по созданию часовой брони -->
    <v-dialog v-model="$store.state.openHourRoom" width="882">
      <calendarHour />
    </v-dialog>

    <v-overlay :z-index="1001" :value="loader">
      <v-progress-circular
        :size="150"
        :width="7"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import calendarHour from "../../components/calendar/Hour";
import activeRest from "../../components/calendar/activeRest";
import GSTC from "vue-gantt-schedule-timeline-calendar";
import api from "gantt-schedule-timeline-calendar";
import BookingModal from "./pages/BookingModal";
import { mapMutations, mapGetters } from "vuex";
import QuestionReserve from "./components/QuestionReserve";
import moment from "moment";
import "moment-timezone";
import user from "../../store/auth/index";
import toast from "../../plugins/toast";
import { CalendarApiUrls } from "@/services/accommodationRequests/calendar.api.js";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";
import { CompaniesApiUrls } from "@/services/accommodationRequests/companies.api.js";

export default {
  components: {
    QuestionReserve,
    BookingModal,
    GSTC,
    calendarHour,
    activeRest,
  },
  data() {
    return {
      scrollLeft: 0, // scroll coordinate horizontally px for gstc
      saveBtnLoading: false,
      disabledBtn: false,
      object_type: localStorage.getItem("object_type"),
      check_in: 0,
      check_out: 0,
      residence: 0,
      loader: true,
      forceUpdate: 0,
      free: 0,
      window: window,
      busy: 0,
      paid: 0,
      not_paid: 0,
      partial_paid: 0,
      busy_times: 0,
      busy_koika: 0,
      period: 60,
      periods: [
        { text: "7", value: 7 },
        { text: "15", value: 15 },
        { text: "30", value: 30 },
        { text: "60", value: 60 },
      ],
      dialog: false,
      today: new Date().toISOString().substr(0, 10),
      text: "",
      categories: [],
    };
  },
  watch: {
    // если вручную изменится query param today, то вызвать функцию
    "$route.query.today"() {
      if (this.today && !this.$route.query.today) {
        this.newPeriodSegment(this.$store.state.searchString);
      }
    },
    // если закрылся блок попап, обновить шахматку
    "$store.state.isBlockOpen"() {
      if (!this.$store.state.isBlockOpen) {
        this.loader = true;
        this.$store.commit("CLEAR_CALENDAR"); // почистить шахматку
        this.forceUpdate++;
        this.forceUpdate++;
        this.getList(this.$store.state.searchString);
        this.loadReport();
      }
    },
    // если изменится search сверху, то обновить шахматку
    "$store.state.searchString": function () {
      if (user.getters.getCompany) {
        this.loader = true;
        this.$store.commit("CLEAR_CALENDAR"); // почистить шахматку
        this.forceUpdate++;
        this.forceUpdate++;
        this.getList(this.$store.state.searchString);
        this.loadReport();
      } else {
        this.loader = false;
      }
    },
  },
  computed: {
    ...mapGetters(["getblockSteady", "getTooltipInfo"]),
    // если нажали на ремонт блок, getrepairPayload() тут будет храниться информация об этом блоке
    repairPayload() {
      return this.$store.getters.getrepairPayload();
    },
    // это конфигурация gstc то есть шахматки
    config: function () {
      // если нет actions в config gstc, дать эти actions
      if (typeof this.$store.state.config.actions === "undefined") {
        this.$store.commit("ADD_ACTIONS");
      }
      return this.$store.state.config;
    },
    computedCurrentDate() {
      return moment(this.today).format("DD.MM.YYYY");
    },
  },
  filters: {
    getStatus(value) {
      let obj = {
        reserved: "Резерв",
        booked: "Бронь",
        check_in: "Заселен",
        block: "Блок",
        time: "Почасовая",
        check_out: "Выселен",
        repaired: "Ремонт",
        koika: "Койка",
      };
      return obj[value];
    },
    convertDate(value) {
      return moment(new Date(value)).format("DD.MM.YYYY HH:mm");
    },
  },
  mounted() {
    if (this.object_type != "is_leisure") {
      // открываем ws
      let socket = new WebSocket(
        `${process.env.VUE_APP_WS_URL}/ws/v2/calendar/${localStorage.getItem(
          "company_id"
        )}/`
      );
      socket.onopen = function (e) {
        console.log("Socket opened!");
      };

      socket.onmessage = (event) => {
        let arr = [JSON.parse(event.data).message];
        this.initOrders(arr, arr[0].category_id, arr[0].room_id);
      };

      socket.onclose = function (event) {
        if (event.wasClean) {
          console.log(
            `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
          );
        } else {
          console.log("[close] Соединение прервано");
        }
      };

      socket.onerror = function (error) {
        console.log(`[error] ${error.message}`);
      };
      // добавляем scroll event, если страница вниз скроллится то header шахматки тоже должен вниз смещаться
      window.addEventListener("scroll", (e) => {
        if (this.$route.path != "/") return;
        if (document.querySelector("#gstc").getBoundingClientRect().y < 0) {
          document.querySelector(
            ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-0"
          ).style.top =
            +document.querySelector("#gstc").getBoundingClientRect().y * -1 +
            "px";
          document.querySelector(
            ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-1"
          ).style.top =
            +document.querySelector("#gstc").getBoundingClientRect().y * -1 +
            "px";
        } else {
          document.querySelector(
            ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-0"
          ).style.top = "0px";
          document.querySelector(
            ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-1"
          ).style.top = "0px";
        }
      });
    }
  },
  created() {
    const localStoragePeriod = localStorage.getItem("period");
    const localStorageToday = localStorage.getItem("today");
		// console.log(localStoragePeriod, localStorageToday);

    
    if (localStoragePeriod && localStorageToday) {
      this.period = Number(localStoragePeriod);
      this.today = localStorageToday;
      this.newPeriodSegment(this.$store.state.searchString);
    } 

    this.$store.commit("SET_EMPTY_BED_ARRAY"); // появилась новая сущность: койки, поэтому я их сохраняю в store
    this.$store.commit("CLEAR_ROWS"); // почистить шахматку
    this.loadReport();
    if (user.getters.getCompany) {
      this.getList();
    } else {
      this.loader = false;
    }
    this.addClickTooltipBody();
    window.addEventListener("scroll", () => {
      localStorage.setItem("scrollPosition", window.scrollY.toString());
    });
  },
  methods: {
    ...mapMutations({
      prevDay: "PREV_DAY",
      nextDay: "NEXT_DAY",
      changePeriod: "CHANGE_PERIOD",
      addCategories: "ADD_CATEGORIES",
      addRooms: "ADD_ROOMS",
      addLength: "CHANGE_CATEGORY_LENGTH",
      updateOrder: "UPDATE_ORDER",
      setWidth: "SET_WIDTH",
    }),
    // скачать excel
    async exportExcel(search = "") {
      // query params
      let today = this.$route.query.today
        ? this.$route.query.today
        : this.today;
      let period = this.$route.query.period
        ? this.$route.query.period
        : this.period;
      today = new Date(today);
      var today_new = today.getTime() / 1000;
      function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }
      var tomorrow_new = addDays(today, period).getTime() / 1000;

      let params = {
        start: today_new,
        end: tomorrow_new,
      };
      if (search.length > 0) {
        params.search = search;
      }
      try {
        let res = (await CalendarApiUrls.exportExcel(params)).data;
        console.log(res);
        var blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment",
        });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(blob, "report.xlsx");
        } else {
          if (window.innerHeight < 500 || window.innerWidth < 500) {
            var ios =
              !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
            var isSafari =
              navigator.vendor &&
              navigator.vendor.indexOf("Apple") > -1 &&
              navigator.userAgent &&
              navigator.userAgent.indexOf("CriOS") == -1 &&
              navigator.userAgent.indexOf("FxiOS") == -1;
            if (ios && !isSafari) {
              toast.error("Вы можете скачать отчеты в Safari");
            } else {
              var reader = new FileReader();
              reader.fileName = "report.xlsx";
              var out = new File([res], "report.xlsx", {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment; filename='report.xlsx'",
              });
              reader.onload = function (e) {
                window.location.href = reader.result;
              };
              reader.readAsDataURL(out);
            }
          } else {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report.xlsx");
            if (typeof link.download === "undefined") {
              link.setAttribute("target", "_blank");
            }
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            }, 100);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    // изменить статус брони в tooltip
    async changeStatus(data) {
      this.saveBtnLoading = true;
      this.disabledBtn = true;
      try {
        let body = { booking_id: data.item.id };
        if (data.item.status == "reserved") {
          let resp = await BookingApiUrls.bookBoking(body);
          toast.success("Забронировано!");
          this.outClick();
        } else if (data.item.status == "booked") {
          let resp = await BookingApiUrls.setCheckin(body);
          toast.success("Заселили!");
          this.outClick();
        } else if (data.item.status == "check_in") {
          let resp = await BookingApiUrls.setCheckout(body);
          toast.success("Выселили!");
          this.outClick();
        }
      } finally {
        this.disabledBtn = false;
        this.saveBtnLoading = false;
      }
    },
    // дать название кнопке с статусом в tooltip
    btnLabel(data) {
      let label;
      let obj = {
        reserved: "Бронирование",
        booked: "Заселить",
        check_in: "Выселить",
      };
      label = obj[data.status];
      return label;
    },
    // открыть попап с квизом опреденной брони
    openPopup(data) {
      this.$store.commit("OPEN_POPUP", data);
    },
    // добавить click event на body если tooltip открыт, чтобы его закрыть
    addClickTooltipBody() {
      document.addEventListener("click", (event) => {
        let bool = event
          .composedPath()
          .includes(document.querySelector(".tooltip_relative"));
        if (this.$store.state.is_tooltip && !bool)
          this.$store.commit("CLOSE_TOOLTIP");
        for (const [key, value] of Object.entries(
          this.$store.state.config.chart.items
        )) {
          if (this.$store.state.config.chart.items[key].style.background_upd) {
            this.$store.state.config.chart.items[key].style.background =
              this.$store.state.config.chart.items[key].style.background_upd;
            this.$store.state.config.chart.items[key].style.color =
              this.$store.state.config.chart.items[key].style.color_upd;
          }
        }
      });
    },
    // tooltip left style
    getToolTipLeft(item) {
      if (item.left)
        return (
          this.$store.state.config.list.columns.data.label.width +
          item.left -
          this.scrollLeft +
          "px"
        );
      return "0px";
    },
    // tooltip top style
    getToolTipTop(item) {
      let dif = 125;
      if (!item.item.common_amount) dif -= 20;
      if (item.row.top) return item.row.top - dif + "px";
      return "0px";
    },
    // изменить ширины колонки Категории в шахматке
    changeWidth() {
      if (this.$store.state.config.list.columns.data.label.width == 100) {
        this.setWidth(200);
      } else {
        this.setWidth(100);
      }
    },
    setCategories() {
      console.log(1);
    },
    // превратить блок в резерв
    reserveBlocks() {
      if (this.getblockSteady().is_own) {
        this.disabledBtn = true;
        let from = this.getblockSteady().start;
        let to = this.getblockSteady().end;
        window.axios
          .post("/api/booking/", {
            check_in: `${from} ${user.getters.getCheckInTime}`,
            check_out: `${to} ${user.getters.getCheckOutTime}`,
            room: this.getblockSteady().room,
          })
          .then((resp) => {
            this.disabledBtn = false;
            this.$store.commit("SET_STEADY", resp.data);
            this.$store.commit("OPEN_CLOSE_RESERVE", { status: "new" });
            this.$store.commit("TO_TAB", 2);
            this.$store.commit("OPEN_BLOCK", false);
          })
          .catch((err) => {
            this.disabledBtn = false;
            toast.error(err.response.data.error);
          });
      } else {
        toast.error("Нельзя использовать чужой блок");
      }
    },
    // сохранить блок
    saveBlocks() {
      this.disabledBtn = true;
      window.axios
        .patch(`/api/chanel/block/${this.getblockSteady().id}/`, {
          start: this.getblockSteady().start,
          end: this.getblockSteady().end,
        })
        .then((resp) => {
          this.disabledBtn = false;
          toast.success("Даннные успешно поменялись");
          this.$store.commit("CLEAR_CALENDAR"); // почистить шахматку
          this.$store.commit("OPEN_BLOCK", false);
        });
    },
    // удалить блок
    removeBlocks() {
      this.disabledBtn = true;
      let post_data = {
        room: this.getblockSteady().room,
      };
      window.axios
        .post(
          `/api/chanel/block/${this.getblockSteady().id}/remove_only_room/`,
          post_data
        )
        .then(() => {
          this.disabledBtn = false;
          toast.success("Удалено");
          this.$store.commit("CLEAR_CALENDAR"); // почистить шахматку
          this.$store.commit("OPEN_BLOCK", false);
        });
    },
    // закрыть блок
    cancelBlocks() {
      this.$store.commit("OPEN_BLOCK", false);
    },
    // виды оплат в step 4
    async getPaymentTypes() {
      let resp = await CompaniesApiUrls.getTypes({});
      this.$store.commit("SET_PAYMENT_TYPES", resp);
    },
    // вытянуть все категории
    async getCategories() {
      let params = {
        "page[size]": 100000,
        "page[number]": 1,
      };
      let resp = await CategoriesApiUrls.getCategories(params);
      this.$store.commit("SET_POSSIBLE_CATEGORIES", resp);
    },
    // открыть ремонт, то есть удалить его
    async openRepair() {
      this.disabledBtn = true;
      try {
        await CategoriesApiUrls.deleteRepairRoom(this.repairPayload.item.id);
        this.$store.commit("SET_OPENREPAIR", false);
        this.$store.commit("CLEAR_CALENDAR"); // почистить шахматку
        this.outClick();
      } finally {
        this.disabledBtn = false;
      }
    },
    // fetch шахматки
    async getList(search = "") {
      // query params
      let today = this.$route.query.today
        ? this.$route.query.today
        : this.today;
      let period = this.$route.query.period
        ? this.$route.query.period
        : this.period;
      today = new Date(today);
      var today_new = today.getTime() / 1000;
      function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }
      var tomorrow_new = addDays(today, period).getTime() / 1000;

      let params = {
        start: today_new,
        end: tomorrow_new,
      };
      if (search.length > 0) {
        params.search = search;
      }
      try {
        let response = await CalendarApiUrls.getCalendar(params);

        this.loader = false;
        // добавляем категории
        this.initCategory(response.data);
        // добавляем комнаты
        for (let i = 0; i < response.data.length; i++) {
          this.initRooms(
            response.data[i].room_list,
            response.data[i].id,
            false,
            response.data[i].category_type
          );
        }
        for (let i = 0; i < response.data.length; i++) {
          for (let j = 0; j < response.data[i].room_list.length; j++) {
            // добавляем койки и брони, которые лежат в комнатах
            this.initOrders(
              response.data[i].room_list[j].booking_list,
              response.data[i].id,
              response.data[i].room_list[j].id
            );
            this.initRooms(
              response.data[i].room_list[j].koika_list,
              response.data[i].room_list[j].id,
              true
            );
            for (
              let k = 0;
              k < response.data[i].room_list[j].koika_list.length;
              k++
            ) {
              // добавляем брони, которые лежат в койках
              this.initOrders(
                response.data[i].room_list[j].koika_list[k].booking_list,
                response.data[i].room_list[j].id,
                response.data[i].room_list[j].koika_list[k].id
              );
            }
          }
        }
        // считаем height шахматки по количеству room_list
        let height = 0;
        response.data.forEach((e) => {
          height += e.room_list.length;
          e.room_list.forEach((j) => {
            height += j.koika_list.length;
          });
        });
        height += response.data.length;
        if (height < 5) this.$store.commit("SET_HEIGHT", height * 70);
        else this.$store.commit("SET_HEIGHT", height * 50);
        this.getCategories(); // fetch categories
        this.getPaymentTypes(); // fetch payment types for step 4

        setTimeout(() => {
          let arr = [];
          if (this.$mobileCheck) {
            arr = document.querySelectorAll(
              ".gantt-schedule-timeline-calendar__list-column-row-expander-toggle-child"
            );
          }
          for (let item of arr) {
            if (item.style.display == "none") {
              // убираем одну вещь toggle чтобы в мобилке не мешало
              item.parentNode.parentNode.style.display = "none";
            }
          }
          // ставим mutation observer чтобы ширина двух блоков была одинаковой, которые лежат друг на друга
          // поэтому визуально это будто один блок
          // с левой стороны колонка Категория
          const Observe = (sel, opt, cb) => {
            const Obs = new MutationObserver((m) => [...m].forEach(cb));
            document
              .querySelectorAll(sel)
              .forEach((el) => Obs.observe(el, opt));
          };
          Observe(
            ".gantt-schedule-timeline-calendar__list-column",
            {
              attributesList: ["style"], // Only the "style" attribute
              attributeOldValue: true, // Report also the oldValue
            },
            (m) => {
              document.querySelector(
                ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-0"
              ).style.paddingLeft = m.target.style.width;
              document.querySelector(
                ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-1"
              ).style.paddingLeft = m.target.style.width;
              document.querySelector(
                ".gantt-schedule-timeline-calendar__chart-timeline"
              ).style.paddingLeft = m.target.style.width;
            }
          );
          // scroll event handler for tooltip left coordinate
          document
            .querySelector("#gstc")
            .addEventListener("scroll", (event) => {
              if (
                this.$store.state.is_tooltip &&
                this.scrollLeft !== event.target.scrollLeft
              ) {
                // horizontally scrolled
                this.scrollLeft = event.target.scrollLeft;
              }
            });
          if (this.object_type != "is_leisure") {
            if (this.$mobileCheck) {
              this.setWidth(100);
              // задать style в самом начале
              document.querySelector(
                ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-0"
              ).style.paddingLeft = "100px";
              document.querySelector(
                ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-1"
              ).style.paddingLeft = "100px";
              document.querySelector(
                ".gantt-schedule-timeline-calendar__chart-timeline"
              ).style.paddingLeft = "100px";
            } else {
              document.querySelector(
                ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-0"
              ).style.paddingLeft = "250px";
              document.querySelector(
                ".gantt-schedule-timeline-calendar__chart-calendar-dates--level-1"
              ).style.paddingLeft = "250px";
              document.querySelector(
                ".gantt-schedule-timeline-calendar__chart-timeline"
              ).style.paddingLeft = "250px";
            }
          }
          if (
            this.object_type != "is_leisure" &&
            this.$route.query.period &&
            this.$route.query.today
          ) {
            this.today = this.$route.query.today;
            this.period = Number(this.$route.query.period);
            this.changePeriod({
              period: Number(this.$route.query.period),
              today: this.$route.query.today,
            });
          } else if (this.object_type != "is_leisure") {
            this.today = new Date().toISOString().substr(0, 10);
            this.period = 60;
            this.changePeriod({
              period: this.period,
              today: this.today,
            });
          }
        }, 0);
        // если нет actions в config gstc, дать эти actions
        if (typeof this.$store.state.config.actions === "undefined") {
          this.$store.commit("ADD_ACTIONS");
        }
      } finally {
        this.loader = false;
      }

      setTimeout(() => {
        const savedScrollPosition = localStorage.getItem("scrollPosition");
        if (savedScrollPosition) {
          window.scrollTo(0, Number(savedScrollPosition));
        }
      }, 100);
    },
    // изменение query params
    newPeriodSegment(value) {
      // Обновляем localStorage при изменении селектора
      localStorage.setItem("period", this.period.toString());
      localStorage.setItem("today", this.today);

      this.loader = true;
      this.$router.push({
        path: this.$route.path,
        query: { period: this.period, today: this.today },
      });
      this.getList(value);
    },
    // проинициализировать категории
    initCategory(response) {
      if (response.length > 0) {
        response.map((el) => {
          this.addCategories(el);
          this.addLength(el.id);
          this.categories.push(el);
        });
      }
    },
    // проинициализировать комнаты и койки
    initRooms(response, id, bool, category_type) {
      if (response) {
        response.map((el) => {
          el.category = id;
          if (category_type) el.category_type = category_type;
          if (el.koika_list && el.koika_list.length > 0) {
            el.category = id;
            this.$store.commit(
              "SET_BED_ARRAY",
              el.id + this.$store.state.count
            );
            this.addCategories(el);
            this.categories.push(el);
          } else {
            el.is_bed = bool;
            this.addRooms(el);
          }
        });
      }
    },
    // проинициализировать брони
    // добавить брони в шахматку gstc в config
    initOrders(response, id, id_room) {
      if (response.length > 0) {
        response.map((el) => {
          el.category_id = id;
          el.room = id_room;
          this.updateOrder(el);
        });
        const state = api.api.stateFromConfig(this.$store.state.config);
        this.categories.map((el) => {
          state.update(`config.list.rows.${el.id}.expanded`, true);
        });
      }
    },
    next() {
      console.log(new Date(this.today), this.today);
      this.today = moment(new Date(this.today))
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.nextDay();

      this.getList(this.$store.state.searchString);
    },
    prev() {
      this.today = moment(new Date(this.today))
        .add(-1, "days")
        .format("YYYY-MM-DD");
      this.prevDay();

      this.getList(this.$store.state.searchString);
    },
    async loadReport() {
      try {
        let res = await CalendarApiUrls.getToday();
        this.free = res.empty;
        this.busy = res.busy;
        this.check_in = res.check_ins;
        this.check_out = res.check_outs;
        this.residence = res.accommodations;
        this.paid = res.paid;
        this.not_paid = res.not_paid;
        this.partial_paid = res.partial_paid;
        this.busy_times = res.busy_times;
        this.busy_koika = res.busy_koika;
      } finally {
        this.loader = false;
      }
    },
    // очистить шахматку и заново построить шахматку
    outClick() {
      this.loader = true;
      this.$store.state.reserve = false;
      this.$store.commit("SET_UPDATED_STEADY", 0);
      this.$store.commit("SET_ACTION");
      this.$store.commit("CLEAR_ROWS"); // почистить шахматку
      this.$store.commit("CLEAR_CALENDAR"); // почистить шахматку
      this.loader = true;
      setTimeout(() => {
        this.getList();
        this.loadReport();
      }, 0);
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(194, 192, 192);
  border-radius: 10px;
}
.tooltip_relative {
  position: relative;
}
.tooltip_absolute {
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  z-index: 10;
  width: max-content;
  padding: 10px 20px;
  box-shadow: 0px 10px 30px rgba(138, 149, 158, 0.1);
  border: 1px solid #e0e0e0;
}
#gstc {
  overflow-x: scroll;
}
#gstc * {
  transition: 0.2s all ease;
}
.font-small {
  font-size: 14px;
  line-height: 17px;
}
.gantt-schedule-timeline-calendar__list-column {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  min-width: 100px !important;
}
.gantt-schedule-timeline-calendar__list-column-row-content {
  pointer-events: none !important;
  touch-action: none !important;
  user-select: none !important;
}
.gantt-schedule-timeline-calendar__chart-calendar-dates--level-0 {
  position: relative;
  z-index: 1;
  top: 0px;
  background-color: #fff;
}
.gantt-schedule-timeline-calendar__chart-calendar-dates--level-1 {
  position: relative;
  top: 0px;
  z-index: 1;
  background-color: #fff;
}
@media screen and (orientation: portrait) {
  .font-small {
    font-size: 2.88vw;
    line-height: 4.1vw;
  }
  .gantt-schedule-timeline-calendar__list-column-row-expander-toggle {
    padding-left: 0vw;
  }
}
.gantt-schedule-timeline-calendar__list > * {
  touch-action: auto;
}
.p-0 {
  padding: 0 !important;
}
.v-card {
  margin-top: 0 !important;
}
.v-card__title {
  line-height: 1rem !important;
}
.highlight {
  background-color: #ffff00;
}
.gantt-schedule-timeline-calendar__chart-timeline-items-row-item {
  border-radius: 0 !important;
  margin-top: 1px !important;
  height: 37px !important;
  padding: 8px 0 !important;
}
.gantt-schedule-timeline-calendar__chart-calendar-date--level-0
  .gantt-schedule-timeline-calendar__chart-calendar-date-content {
  margin: 7px !important;
  color: #181818 !important;
}
.gantt-schedule-timeline-calendar__chart-calendar-date-content {
  color: #181818 !important;
}
.gantt-schedule-timeline-calendar__list-column-row,
.gantt-schedule-timeline-calendar__chart-calendar-date,
.gantt-schedule-timeline-calendar__chart-timeline-grid-row-block {
  height: 40px !important;
  touch-action: auto;
}
.weekends-highlight {
  background-color: rgba(255, 196, 126, 0.5);
}
.gantt-schedule-timeline-calendar__chart-calendar-date--month {
  border-left: 2px solid rgba(107, 107, 107, 0.75) !important;
}
.gantt-schedule-timeline-calendar__chart-timeline-grid-row-block,
.gantt-schedule-timeline-calendar__list-column-header-content,
.gantt-schedule-timeline-calendar__list-column-row,
.gantt-schedule-timeline-calendar__chart-calendar-date {
  border-right: 1px solid #353535 !important;
  border-bottom: 1px solid #353535 !important;
}
.gantt-schedule-timeline-calendar__chart-calendar-date-content.gstc-date-small {
  line-height: 15px !important;
}
.gantt-schedule-timeline-calendar__chart-timeline-grid-row {
  height: 40px !important;
}
.gantt-schedule-timeline-calendar__chart-timeline-items-row {
  height: 40px !important;
}
.gantt-schedule-timeline-calendar__chart-calendar-date-content--day,
.gantt-schedule-timeline-calendar__chart-calendar-date-content--week {
  line-height: 15px !important;
}
.gantt-schedule-timeline-calendar__list-column-header-resizer-container {
  color: #181818 !important;
  touch-action: none !important;
  user-select: none !important;
  pointer-events: none !important;
}
.gantt-schedule-timeline-calendar__list-column-row-content {
  color: #181818 !important;
}
.additional-class {
  color: black;
  background-color: black;
}
.gantt-schedule-timeline-calendar__chart-timeline-items-row-item {
  padding: 3px 0 !important;
}
.gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label {
  line-height: 13px;
}
/* .gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label span {
  font-size: 0.7vw !important;
} */
/* .gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label_span {
  display: inline-block;
} */
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none;
}

.select-selected {
  background-color: DodgerBlue;
}
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.gantt-schedule-timeline-calendar__list-column-row-expander-toggle-child {
  display: none;
}
.gantt-schedule-timeline-calendar__list-column-header-resizer-container {
  padding-left: 35px;
  touch-action: none;
  pointer-events: none;
}
@media screen and (max-width: 500px) {
  .gantt-schedule-timeline-calendar__list-column-header-resizer-container {
    padding-left: 0px;
  }
}
.gantt-schedule-timeline-calendar__horizontal-scroll {
  display: none;
}
.gantt-schedule-timeline-calendar__chart,
.gantt-schedule-timeline-calendar__chart-timeline-grid,
.gantt-schedule-timeline-calendar__chart-timeline,
.gantt-schedule-timeline-calendar__chart-timeline-inner {
  position: relative;
}

@font-face {
  font-family: "PF Square Sans Pro";
  src: url("../../fonts/PFSquareSansPro-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
.gantt-schedule-timeline-calendar__chart-timeline-items-row-item {
  overflow: visible;
}
.gantt-schedule-timeline-calendar__chart-timeline-grid {
  height: unset !important;
  overflow: hidden;
}
.gantt-schedule-timeline-calendar__chart-timeline-items {
  height: auto !important;
}
html {
  overflow-y: auto;
}
</style>
