<template>
  <!-- форма бронирования по часам  -->
  <v-card class="pa-5">
    <h1 class="hour_title" style="text-align: center">Бронирование по часам</h1>
    <v-row class="px-3">
      <v-spacer />
    </v-row>
    <v-row class="px-3 pt-4 pb-5">
      <v-btn @click="moveCalendar('back')" icon color="primary">
        <v-icon>mdi-arrow-left-bold-circle</v-icon>
      </v-btn>
      <v-btn @click="moveCalendar('forward')" icon color="primary">
        <v-icon>mdi-arrow-right-bold-circle</v-icon>
      </v-btn>
      <v-checkbox class="my-1 ml-2" v-model="not_fix" label="12 часов">
      </v-checkbox>
      <v-spacer />
      <h2>Дата: {{ formatCurrentDate() }}</h2>
    </v-row>
    <div class="hour_calendar_wrapper" @mouseleave="endSelect">
      <div v-for="(item, index) in hoursArr" :key="index" class="single_cell">
        <div class="single_cell__hour">
          {{ item.date.substring(10, 13) }}
        </div>
        <div
          class="single_cell__box"
          @click="startSelect(item)"
          :class="getClasses(item)"
        >
          <div
            class="single_booking"
            v-for="booking in bookings"
            :key="booking.id"
            style="cursor: pointer"
          >
            <div
              v-if="checkBookingTime(booking, item)"
              @click="getDetails(booking)"
              style="cursor: pointer"
              class="single_booking__cell"
              :id="'book_' + booking.id"
              :style="{ background: statusColor(booking.booking_status) }"
            >
              <div
                v-if="bookingStart(booking, item)"
                class="booking_information"
                :style="{
                  background: statusColor(booking.booking_status),
                  width: computedWidth(booking, item)
                }"
              >
                <div class="booking_information__header">
                  <div>
                    <p>{{ booking.client_name || "Не заполнено!" }}</p>
                  </div>
                  <div>
                    <p>{{ booking.duty }} тг</p>
                  </div>
                </div>
                <div class="booking_information__footer">
                  <p>{{ booking.source_of_sales }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- попап когда уже выбрано время и нужно зарезервировать -->
    <v-dialog v-model="dialog" width="700" @click:outside="closeReserveModal">
      <v-card class="pa-4">
        <v-row>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="reserveParams.check_in"
              readonly
              label="Время заезда"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="reserveParams.check_out"
              readonly
              label="Время выезда"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-btn
            @click="goBooking('reserve')"
            color="primary"
            :disabled="disabledBtn"
            >резерв</v-btn
          >
          <v-btn @click="closeReserveModal">Отмена</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";
import { CalendarApiUrls } from "@/services/accommodationRequests/calendar.api.js";

export default {
  data: () => ({
    clickCount: 0,
    reserveParams: {},
    disabledBtn: false,
    currentState: {},
    currentDate: "",
    dialog: false,
    isSelect: false,
    bookings: [],
    hoursArr: [
      {
        time: "00:00",
        hour: 0
      },
      {
        time: "01:00",
        hour: 1
      },
      {
        time: "02:00",
        hour: 2
      },
      {
        time: "03:00",
        hour: 3
      },
      {
        time: "04:00",
        hour: 4
      },
      {
        time: "05:00",
        hour: 5
      },
      {
        time: "06:00",
        hour: 6
      },
      {
        time: "07:00",
        hour: 7
      },
      {
        time: "08:00",
        hour: 8
      },
      {
        time: "09:00",
        hour: 9
      },
      {
        time: "10:00",
        hour: 10
      },
      {
        time: "11:00",
        hour: 11
      },
      {
        time: "12:00",
        hour: 12
      },
      {
        time: "13:00",
        hour: 13
      },
      {
        time: "14:00",
        hour: 14
      },
      {
        time: "15:00",
        hour: 15
      },
      {
        time: "16:00",
        hour: 16
      },
      {
        time: "17:00",
        hour: 17
      },
      {
        time: "18:00",
        hour: 18
      },
      {
        time: "19:00",
        hour: 19
      },
      {
        time: "20:00",
        hour: 20
      },
      {
        time: "21:00",
        hour: 21
      },
      {
        time: "22:00",
        hour: 22
      },
      {
        time: "23:00",
        hour: 23
      }
    ],
    selectedBoxes: [],
    not_fix: false
  }),
  computed: {
    ...mapGetters(["getCurrentRoomHour", "getopenHourRoom"])
  },
  watch: {
    getopenHourRoom() {
      if (this.getopenHourRoom) {
        this.currentState = this.getCurrentRoomHour();
        this.currentDate = this.currentState.id;
        this.fillHoursArray();
        this.getBookings();
      }
    }
  },
  created() {
    this.currentState = this.getCurrentRoomHour();
    this.currentDate = this.currentState.id;
    this.fillHoursArray();
    this.getBookings();
  },
  methods: {
    computedWidth(booking, item) {
      // подсчет ширины брони
      let startBox = moment(booking.check_in * 1000).format("YYYY-MM-DD HH:mm");
      let endBox = moment(booking.check_out * 1000).format("YYYY-MM-DD HH:mm");
      let end = "";
      let start = "";
      let diff = "";
      start = moment(booking.check_in * 1000);
      end = moment(booking.check_out * 1000);
      if (endBox <= this.hoursArr[this.hoursArr.length - 1].date) {
        end = moment(booking.check_out * 1000);
      }
      if (
        this.hoursArr[0].date == item.date &&
        this.hoursArr[0].date >= startBox
      ) {
        diff = end.diff(this.hoursArr[0].date, "hours");
      } else {
        diff = end.diff(start, "hours");
      }
      return `${diff * 35}px`;
    },
    fillHoursArray() {
      // добавление сегодняшнего дня в hoursArr
      this.hoursArr.map((el, index) => {
        el.date = moment(this.currentDate)
          .add(index, "hours")
          .format("YYYY-MM-DD HH:mm");
      });
    },
    getDetails(booking) {
      // если есть бронь и на нее нажали, работает эта функция
      let payload = {
        item: {
          id: booking.id,
          status: booking.status
        }
      };
      this.$store.commit("OPEN_POPUP", payload);
    },
    statusColor(status) {
      const colors = {
        reserved: "orange",
        booked: "yellow",
        check_in: "rgb(22, 221, 22)",
        block: "rgba(79, 0, 255, .5)",
        canceled: "lightgray",
        client_cancel: "lightgray",
        company_cancel: "lightgray",
        expired: "lightgray",
        check_out: "lightgray",
        repair: "lightgray"
      };
      return colors[status];
    },
    bookingStart(booking, item) {
      // проверка на то где стоит бронь по часам
      let start = moment(booking.check_in * 1000).format("YYYY-MM-DD HH:mm");
      if (item.date == start) {
        return true;
      } else if (
        this.hoursArr[0].date == item.date &&
        this.hoursArr[0].date >= start
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkBookingTime(booking, item) {
      // проверка если бронь заходит в 24 часа по времени
      let start = moment(booking.check_in * 1000).format("YYYY-MM-DD HH:mm");
      let end = moment(booking.check_out * 1000).format("YYYY-MM-DD HH:mm");
      if (start <= item.date && item.date < end) {
        return true;
      } else {
        return false;
      }
    },
    formatCurrentDate() {
      return moment(this.hoursArr[0].date).format("DD.MM.YYYY HH:mm");
    },
    async getBookings() {
      // вытянуть брони
      console.log(this.hoursArr);
      let start = this.hoursArr[0].date,
        end = this.hoursArr[this.hoursArr.length - 1].date;
      start = start.split(" ");
      start[0] = start[0].split("-");
      start[1] = start[1].split(":");
      end = end.split(" ");
      end[0] = end[0].split("-");
      end[1] = end[1].split(":");
      console.log(start, end);
      start =
        new Date(
          start[0][0],
          +start[0][1] - 1,
          start[0][2],
          start[1][0],
          start[1][1]
        ).getTime() / 1000;
      end =
        new Date(
          end[0][0],
          +end[0][1] - 1,
          end[0][2],
          end[1][0],
          end[1][1]
        ).getTime() / 1000;
      console.log(start, end);
      let params = {
        start: start,
        end: end,
        room_id: this.getCurrentRoomHour().rowId
      };
      let resp = await CalendarApiUrls.getHourCalendar(params);
      this.bookings = resp;
    },
    getClasses(item) {
      let boxClass = "";
      this.selectedBoxes.map(el => {
        if (el.hour == item.hour) {
          boxClass = "selectedBox";
        }
      });
      return boxClass;
    },
    moveCalendar(action) {
      this.getBookings();
      if (action == "back") {
        this.hoursArr.map(el => {
          el.date = moment(el.date)
            .subtract(1, "hours")
            .format("YYYY-MM-DD HH:mm");
        });
      } else {
        this.hoursArr.map(el => {
          el.date = moment(el.date)
            .add(1, "hours")
            .format("YYYY-MM-DD HH:mm");
        });
      }
    },
    startSelect(item) {
      // функция при клике на ночь
      if (this.clickCount == 0) this.selectedBoxes = [];
      this.clickCount++;
      this.selectedBoxes.push(item);
      if (this.clickCount == 1 && this.not_fix) {
        // condition при котором стоит галочка уже на 12 часов - нефиксированное время
        console.log({ item_date: item.date });
        for (let i = 1; i < 12; i++) {
          this.selectedBoxes.push({
            date: moment(item.date)
              .add(i, "hours")
              .format("YYYY-MM-DD HH:mm")
          });
        }
        this.isSelect = true;
        this.endSelect();
        this.clickCount = 0;
      }
      if (this.clickCount == 2) {
        // condititon если выбрали 2 часа
        let start, end;
        if (this.selectedBoxes[0].date <= this.selectedBoxes[1].date) {
          start = this.selectedBoxes[0];
          end = this.selectedBoxes[1];
        } else {
          start = this.selectedBoxes[1];
          end = this.selectedBoxes[0];
        }
        let started = false;
        if (start != end) {
          this.hoursArr.forEach(e => {
            if (start.date == e.date) {
              started = true;
            } else if (end.date == e.date) {
              started = false;
            }
            if (started) {
              this.selectedBoxes.push(e);
            }
          });
        }
        this.isSelect = true;
        this.endSelect();
        this.clickCount = 0;
      }
    },
    endSelect() {
      if (this.isSelect) {
        // открывается попап с кнопкой резерв
        console.log(this.selectedBoxes);
        let start = Math.min(
          ...this.selectedBoxes.map(e => moment(e.date).local())
        );
        let end = Math.max(
          ...this.selectedBoxes.map(e => moment(e.date).local())
        );
        console.log({ start }, { end });
        this.reserveParams.check_in = moment(start).format("YYYY-MM-DD HH:mm");
        this.reserveParams.check_out = moment(end)
          .add(1, "hours")
          .format("YYYY-MM-DD HH:mm");
        this.dialog = true;
        this.isSelect = false;
      }
    },
    findMinMaxDate(array, type){
      let arr = JSON.parse(JSON.stringify(array));
      arr.forEach(e => {
        let date = e.date;
        e.date = {
          year: date.split(' ')[0].split('-')[0],
          month: Number(date.split(' ')[0].split('-')[1]) - 1,
          day: date.split(' ')[0].split('-')[2],
          hours: date.split(' ')[1].split(':')[0],
          minutes: date.split(' ')[1].split(':')[1],
        };
      });
      console.log(arr);
      if(type == 'min')
        return Math.min(...arr.map(e => new Date(e.date.year, e.date.month, e.date.day, e.date.hours, e.date.minutes)));
      else 
        return Math.max(...arr.map(e => new Date(e.date.year, e.date.month, e.date.day, e.date.hours, e.date.minutes)));
    },
    closeReserveModal() {
      this.dialog = false;
      this.selectedBoxes = [];
    },
    commitReserve(resp) {
      this.$store.commit("SET_STEADY", resp);
      this.$store.commit("OPEN_CLOSE_RESERVE", { status: "new" });
      this.$store.commit("TO_TAB", 2);
    },
    commitBook(resp) {
      this.$store.commit("SET_STEADY", resp.data);
      this.$store.commit("OPEN_CLOSE_RESERVE", { status: "reserve" });
      this.$store.commit("TO_TAB", 2);
    },
    convertStringToDate(value){
      let date = {
        year: value.split(' ')[0].split('-')[0],
        month: Number(value.split(' ')[0].split('-')[1]) - 1,
        day: value.split(' ')[0].split('-')[2],
        hours: value.split(' ')[1].split(':')[0],
        minutes: value.split(' ')[1].split(':')[1],
      };
      return new Date(date.year, date.month, date.day, date.hours, date.minutes)
    },
    async goBooking(type) {
      this.disabledBtn = true;
      let post_data = Object.assign({}, this.reserveParams);



      let body = {
        check_in: this.convertStringToDate(post_data.check_in).getTime() / 1000,
        check_out: this.convertStringToDate(post_data.check_out).getTime() / 1000,
        room: this.currentState.rowId,
        is_time: true
      };
      try {
        let resp = await BookingApiUrls.reserveBooking(body);
        console.log(resp.data);
        this.disabledBtn = false;
        console.log(type);
        if (type == "reserve") {
          // открыть резерв
          this.commitReserve(resp.data);
        } else {
          // открыть бронь
          this.commitBook(resp.data);
        }
        this.dialog = false;
        this.$store.commit("CLOSE_HOUR_CALENDAR");
        this.selectedBoxes = [];
      } finally {
        this.disabledBtn = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hour_title {
  font-size: 2.4vw;
}
@media screen and (max-width: 500px) {
  .hour_title {
    font-size: 5.4vw;
  }
}
.hour_calendar_wrapper {
  display: grid;
  grid-template-columns: repeat(24, 35px);
  overflow-x: visible;
  overflow-y: hidden;
  .single_cell {
    text-align: center;
    &__hour {
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -o-user-select: none;
    }
    &__box {
      position: relative;
      height: 35px;
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
    }
    &:last-of-type {
      border-right: 1px solid #000;
    }
  }
}
.selectedBox {
  background: #0077c0bf;
}
.reservedBox {
  background: #ffa500;
}
.single_booking {
  position: absolute;
  top: -1px;
  left: 0;
  height: 35px;
  &__cell {
    background-color: #fff;
    cursor: pointer;
    height: 35px;
    width: 35px;
    position: relative;
    overflow-x: hidden;
    p {
      margin-bottom: 0 !important;
    }
    .booking_status {
      position: absolute;
      top: 15px;
      left: 4px;
      z-index: 1;
      width: 117px;
    }
    .booking_price {
      position: absolute;
      top: 0px;
      right: 4px;
      text-align: right;
      width: 120px;
    }
  }
}
.booking_information {
  position: absolute;
  top: 0;
  left: 0px;
  height: 35px;
  width: 100%;
  z-index: 1;
  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 6px;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      width: 1px;
      height: 35px;
      background: #000;
      z-index: 10;
    }
    div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      p {
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
      }
      &:first-of-type {
        text-align: left;
      }
      &:last-of-type {
        text-align: right;
      }
    }
  }
  &__footer {
    padding: 0 6px;
    p {
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      font-size: 12px;
    }
  }
}
</style>
