<template>
  <div>
    <v-data-table  :headers="headers" :items="foodList" :key="reactTrigger" :loading="isLoading">
        <template v-slot:[`item.tariff.food_type`]="{ item }">
          <div style="width: 100px">
            <v-select class="text-body-1" :value="item.tariff.food_type" :items="foodTypes" @change="(value) => onChangeFoodType(value, item)" />
          </div>
        </template>
        <template v-slot:[`item.is_advance`]="{ item }">
          <div style="width: 100px">
            <v-select class="text-body-1" v-model="item.is_advance" :items="isAdvanceSelect" />
          </div>
        </template>
        <template v-slot:[`item.adult_count`]="{ item }">
            <v-row>
                <v-col class="pb-0 col-6">Взрослый</v-col>
                <v-col class="flex font-weight-bold pb-0">
                    <v-icon @click="decrement(item, true)">mdi-minus-circle</v-icon>
                    {{ item.adult_count }}
                    <v-icon @click="increment(item, true)" color="warning">mdi-plus-circle</v-icon>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="col-6">Детский</v-col>
                <v-col class="flex font-weight-bold">
                    <v-icon @click="decrement(item, false)">mdi-minus-circle</v-icon>
                    {{ item.child_count }}
                    <v-icon @click="increment(item, false)" color="warning">mdi-plus-circle</v-icon>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.room`]="{ item }">
          <div style="width: 150px">
            <v-select v-if="!getSteady().is_group" v-model="getSteady().room" class="text-body-1" :items="getRooms(item)" item-text="text" />
            <v-select v-else v-model="item.booking" class="text-body-1" :items="getRooms(item)" item-text="text" item-value="value" />
          </div>
        </template>
        <template v-slot:[`item.start_date`]="{ item }">
          <v-row class="align-center">
            <v-col class="pr-1 flex-grow-0">
              <v-icon>mdi-calendar</v-icon>
            </v-col>
            <v-col class="col-5 pa-0">
              <v-select class="text-body-2" v-model="item.start_date" :items="getAllowedDates(item)" item-text="text" />
            </v-col>
            <v-col class="col-5 pa-0">
              <v-select class="text-body-2" v-model="item.end_date" :items="getAllowedDates(item)" />
            </v-col>
          </v-row>
        </template>
      <template v-slot:[`item.total`]="{ item }">
        <p>{{ item.total }}</p>
      </template>
      <!-- <template v-slot:[`item.settings`]="{ item }">

      </template> -->
      <template v-slot:[`item.delete`]="{ item }">
        <v-icon
            class="px-1"
            small
            @click="settingsHandler(item)"
          >
            mdi-cog
          </v-icon>
        <v-icon color="red" @click="deleteHandler(item.id)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-row>
      <v-col>
        <v-btn title color="primary" @click="addFood" :disabled="isLoading">
          <v-icon left>
            mdi-plus
          </v-icon>
          Добавить
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showSettings"
      max-width="600px"
      transition="dialog-bottom-transition"
      persistent
    >
    <v-card>
      <v-card-title
          :style="[
            $mobileCheck
              ? {
                  paddingTop: '1.6vw',
                  paddingBottom: '1vw',
                  paddingLeft: '1vw',
                }
              : { 'padding-top': '11px', 'padding-bottom': '17px' },
          ]"
        >
          <span style="padding-left: 4px; font-size: 16px; line-height: 1rem"
            >Настройки питания</span
          >
        </v-card-title>
        <v-card-text>
            <v-row v-if="selectedItem">
              <v-col v-if="selectedItem.tariff.food_type == 'breakfast' || selectedItem.tariff.food_type == 'all'">
                <v-checkbox
                  v-model="selectedItem.has_breakfast_check_in"
                  label="Завтрак в день заезда"
                />
              </v-col>
              <v-col v-if="selectedItem.tariff.food_type == 'lunch' || selectedItem.tariff.food_type == 'all'">
                <v-checkbox
                  v-model="selectedItem.has_lunch_check_out"
                  label="Обед в день выезда"
                />
              </v-col>
              <v-col v-if="selectedItem.tariff.food_type == 'supper' || selectedItem.tariff.food_type == 'all'">
                <v-checkbox
                  v-model="selectedItem.has_supper_check_out"
                  label="Ужин в день выезда"
                />
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="saveFoodItemSettings()"
          >
            ОК
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
  </div>

</template>

<script>
import {FoodTariffsApiUrls} from "@/services/accommodationRequests/foodTariffs.api";
import {mapGetters} from "vuex";
import moment from "moment/moment";
import toast from "@/plugins/toast";

export default {
  data() {
    return {
      selectedItem: null,
      showSettings: false,
      headers: [
        { text: "Питание", value: "tariff.food_type",   class: "table-title  d-flex",},
        { text: "Тип оплаты", value: "is_advance",  class: "table-title " },
        { text: "Тип питания", value: "adult_count",  class: "table-title col-2" },
        { text: "Комнаты", value: "room",  class: "table-title col-1" },
        { text: "Даты", value: "start_date",  class: "table-title col-3" },
        { text: "К оплате", value: "total" ,  class: "table-title col-1" },
        { text: "", value: "delete", class: "table-title" }
      ],
      foodTypes: [
        { text: "Завтрак", value: "breakfast" },
        { text: "Обед", value: "lunch" },
        { text: "Ужин", value: "supper" },
        { text: "Трехразовое питание", value: "all" },
      ],
      isAdvanceSelect: [
        { text: "На месте", value: false },
        { text: "Заранее", value: true },
      ],
      tariffs: [],
      reactTrigger: 0,
      isLoading: true,
      bouncer: null,
      groupBookingIds: [],
    }
  },
  computed: {
    ...mapGetters(["getSteady"]),
    myEl() {
      return this.$store.getters.getEl();
    },
    foodList() {
      return this.$store.getters.getFoodList;
    },
  },
  watch: {
    async myEl() {
      if (this.myEl === 3) {
        await this.getFoodTariffs();
      }
    },
    foodList: {
      handler(value) {
        for (const item of value) {
          this.updateTotal(item);
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.getFoodTariffs()

    if (this.getSteady().is_group) {
      for (const category of this.getSteady().categories) {
        for (const item of category.child_list) {
          // дублируем по 2 раза для типа оплат "Заранее" / "На месте"
          for (let i = 0; i < 2; i++) {
            // дублируем по 3 раза для времен питания завтрак, обед, ужин
            this.groupBookingIds.push(item.id);
            this.groupBookingIds.push(item.id);
            this.groupBookingIds.push(item.id);
          }
        }
      }
    }

    this.updateAllTotals();
  },
  methods: {
    updateAllTotals() {
      this.getSteady().food_list = this.getSteady().food_list.map((i) => {
        i.total = this.getTotal(i);
        return i;
      });
    },
    getRooms() {
      const rooms = [];
      const steady = this.getSteady();
      if (steady.is_group) {
        for (const category of steady.categories) {
          let index = 0;
          for (const roomId of category.rooms) {
            const currentCategory = this.$store.state.possible_categories.find((i) => i.id === category.child_list[index].category_id);
            const currentRoom = currentCategory.rooms.find((i) => i.id === roomId);
            if (currentRoom) {
              rooms.push({ text: `${currentRoom.name} (${currentCategory.name})`, value: category.child_list[index].id, id: index })
              index += 1;
            }
          }
        }
      } else {
        rooms.push(
          {
            text: `${this.$store.state.steady.room_name} (${this.$store.state.steady.category_name})`,
            value: this.$store.state.steady.room
          }
        )
      }
      return rooms
    },
    settingsHandler(item) {
      this.selectedItem = item;
      this.showSettings = true;
    },
    saveFoodItemSettings() {
      this.selectedItem = null;
      this.showSettings = false;
    },
    deleteHandler(id) {
      this.$store.commit("DELETE_FOOD_LIST_ITEM", { id });
      this.reactTrigger++;
    },
    async increment(item, isAdult) {
      if (isAdult) {
        item.adult_count += 1;
      } else {
        item.child_count += 1;
      }
    },
    async decrement(item, isAdult) {
      if (isAdult) {
        item.adult_count -= item.adult_count === 0 ? 0 : 1;
      } else {
        item.child_count -= item.child_count === 0 ? 0 : 1;
      }
    },
    getPrice(date, item) {
      const weekDay = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(date).toLowerCase();
      const monthName = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date).toLowerCase();
      const adultPrice = item.is_advance ? "adult" : "adult_on_reception"
      const childPrice = item.is_advance ? "children" : "children_on_reception"
      if (item.tariff.price_type === 'period') {
        const adultTariff = item.tariff.months.find((t) => t.price_type === adultPrice)
        const childTariff = item.tariff.months.find((t) => t.price_type === childPrice)
        return (adultTariff[`${monthName}`] * item.adult_count) + (childTariff[`${monthName}`] * item.child_count);
      } else {
        const adultTariff = item.tariff.weekdays.find((t) => t.price_type === adultPrice)
        const childTariff = item.tariff.weekdays.find((t) => t.price_type === childPrice)
        return (adultTariff[`${weekDay}_price`] * item.adult_count) + (childTariff[`${weekDay}_price`] * item.child_count);
      }
    },
    getAllowedDates(item) {
      const startDate = moment(this.$store.state.steady.check_in * 1000).format("YYYY-MM-DD");
      const endDate = new Date(moment(this.$store.state.steady.check_out * 1000).format("YYYY-MM-DD"));

      const dates = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        dates.push(
            {
              text: new Date(currentDate).toLocaleDateString('ru-RU').substring(0, 10) + " - " + this.getPrice(currentDate, item) + "₸",
              value: new Date(currentDate).toISOString().substring(0, 10)
            }
        );
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // убираем первую дату если завтрак, если нет, то последнюю дату
      // if (item.tariff.food_type === "breakfast" && dates.length > 1) {
      //   dates.splice(0, 1);
      // } else if((item.tariff.food_type === "lunch" || item.tariff.food_type === "supper" || item.tariff.food_type === "all") && dates.length > 1) {
      //   dates.pop();
      // }
      return dates;
    },
    onChangeFoodType(value, item) {
      const tariff = this.tariffs.find((i) => i.food_type === value);
      this.$store.commit("SET_FOOD_TYPE", { id: item.id, details: tariff })
    },
    getTotal(item) {
      let total = 0;
      const startDate = item?.start_date;
      const endDate = new Date(item?.end_date);

      const dates = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toISOString().substring(0, 10));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      for (const date of dates) {
        total += this.getPrice(new Date(date), item);
      }
      return total;
    },
    getDate(item, isStart) {
      if (isStart) {
        const date = moment(this.$store.state.steady.check_in * 1000)
        return date.format("YYYY-MM-DD")
      } else {
        const date = moment(this.$store.state.steady.check_out * 1000)
        return date.format("YYYY-MM-DD")
      }
    },
    async addFood() {

      // если тариф питания пустой
      if (!this.tariffs.length) {
        toast.error("Нет доступных тарифов питания");
        return;
      }

      console.log(this.tariffs[0])

      const food = {
        tariff: {
          ...this.tariffs[0],
        },
        adult_count: 0,
        child_count: 0,
        start_date: this.getDate(this.tariffs[0], true),
        end_date: this.getDate(this.tariffs[0], false),
        is_advance: false,
        id: crypto.randomUUID(),
      }

      if (!this.getSteady().is_group) {
        food.booking = this.$store.state.steady.id
      }

      food.total = this.getTotal(food);
      this.$store.commit("ADD_FOOD", food);
      this.reactTrigger++;
    },
    updateTotal(item) {
      item.total = this.getTotal(item);
    },
    async getFoodTariffs() {
      if (this.$store.state.el === 3) {
        let foodTariffDict = {
          breakfast: "Завтрак",
          lunch: "Обед",
          supper: "Ужин",
          all: "Трехразовое питание"
        }
        this.tariffs = await FoodTariffsApiUrls.getTariffsWithPrices();

        let tariffs_temp = (await FoodTariffsApiUrls.getTariffs()).results;
        for (let i = 0; i < tariffs_temp.length; i++) {
           tariffs_temp[i] = await FoodTariffsApiUrls.getTariff(tariffs_temp[i].id);
        }
        console.log(JSON.stringify(tariffs_temp));
        console.log(JSON.stringify(this.tariffs));

        let tempFoodTariffs = []
        this.tariffs.forEach(element => {
          tempFoodTariffs.push({
            text: foodTariffDict[element.food_type],
            value: element.food_type
          })
        });
        this.foodTypes = tempFoodTariffs;

        this.isLoading = false;
      }
    }
  }
}
</script>
<style >
.table-title{
  vertical-align: baseline;
}
</style>