const axios = window.axios
const url = '/api/v2/tariffs/foods/'
const urlPrices = '/api/v2/tariffs/foods/with-prices/'
const urlWeekdays = '/api/v2/tariffs/food-weekdays/'
const urlMonths = '/api/v2/tariffs/food-months/'



export const FoodTariffsApiUrls = {
  // tariffs настройка тарифов лист тарифов
  async getTariffs(params){
    return (await axios.get(`${url}`, { params: params })).data;
  },
  async getTariffsWithPrices(params){
    return (await axios.get(`${urlPrices}`, { params: params })).data;
  },
  async createTariff(body){
    return (await axios.post(`${url}`, body)).data;
  },
  async getTariff(id){
    return (await axios.get(`${url}${id}/`)).data;
  },
  async setTariff(id, body){
    return (await axios.put(`${url}${id}/`, body)).data;
  },
  async deleteTariff(id){
    return (await axios.delete(`${url}${id}/`)).data;
  },


  // months два типа
  async getMonth(id){
    return (await axios.get(`${urlMonths}${id}/`)).data;
  },
  async setMonth(id, body){
    return (await axios.patch(`${urlMonths}${id}/`, body)).data;
  },


  // weekdays два типа
  async getWeekdays(id){
    return (await axios.get(`${urlWeekdays}${id}/`)).data;
  },
  async setWeekdays(id, body){
    return (await axios.patch(`${urlWeekdays}${id}/`, body)).data;
  },
}